import React, { useRef, useState } from 'react';

import tableApi from 'api/table/tableApi';
import { useApi } from 'api/useApi';
import { FwMask, useFwModule } from 'components/base';
import { MaskStructure } from 'components/base/containers/mask/FwMask.structures';

import Table from './components/tableContainer';

const TableMaskContainer = (props: any) => {
  const {
    pageContentID,
    pcTableKey,
    pcAdditionalData,
    tableKey: propTableKey,
  } = props;

  const { module } = useFwModule();

  const moduleKey = module && module.key;
  const tableKey = propTableKey || pcTableKey;

  const newTabLink = pageContentID
    ? `/zoom/${moduleKey.replace('public/', '')}/${pageContentID}`
    : undefined;

  const [args] = useState([tableKey]);

  const { fetched: fetchedTable, pending: pendingTable } = useApi(
    tableApi.getTable,
    args
  );

  const maskRef = useRef(new MaskStructure(pcAdditionalData));

  return !fetchedTable || pendingTable ? (
    <FwMask loading />
  ) : (
    <Table
      {...props}
      tableKey={tableKey}
      newTabLink={newTabLink}
      maskData={maskRef.current}
      table={fetchedTable.table}
    />
  );
};

export default React.memo(TableMaskContainer);
