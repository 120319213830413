import _ from 'lodash';

import { FwToast } from 'components/base';
import { DataProps } from 'components/doc/function/document';
import { Input } from 'core/model';
import { FIELD_TYPE } from 'core/utils/constant';
import utils from 'core/utils/utils';

const { date, datetime, daterange, multidates, reference, collection } =
  FIELD_TYPE;

export const defaultModal = { isOpen: false, modalType: null, options: null };

export const getModalOptions = (modal) => {
  return {
    ...modal,
    // title: modal.name,
    submitName: modal.ok,
    cancelName: modal.cancel,
  };
};

export const buildModalFromProcessInfos = ({
  t,
  okBtnText = t('common|Confirm'),
  sortedEditInputs,
  sortedFilterInputs,
  duplicate,
}: any) => {
  return {
    key: 'PopupProcessKey',
    name: t(
      `common|${
        sortedFilterInputs ? 'Filter' : duplicate ? 'Duplicate/Edit' : 'Edit'
      }`
    ),
    additionalData: {},
    ok: okBtnText,
    cancel: t('common|Cancel'),
    filters: sortedFilterInputs,
    inputs: sortedEditInputs,
  };
};

export const VALID_FUNC_TYPE = {
  charLimit: 'CHAR_LIMIT',
  requiredFields: 'REQUIRED_FIELDS',
  patternFields: 'PATTERN_FIELDS',
};

// check required inputs in document
export const isValidDocument = (t, visibleInputs, docData) => {
  // verify required fields first
  let invalidInputKey = checkIsValidRequiredFields(t, visibleInputs, docData);

  if (!invalidInputKey) {
    // verify limit of char
    //const validCharLimit = checkIsValidCharLimit();

    //if (validCharLimit) {
    // verify pattern fields
    invalidInputKey = checkIsValidPatternFields(t, visibleInputs, docData);
    //}
  }

  return invalidInputKey;
};

export const collectionItemIsEmptyPred = (item: object) =>
  Object.values(item).every(
    (val) =>
      !val || (_.isObject(val) && !(val as { inputValue: string }).inputValue)
  );

// ensure required inputs have values
export const checkIsValidRequiredFields = (
  t,
  fields: Input[],
  formData: DataProps
) => {
  let firstInvalidKey = undefined;

  let firstInvalidFound = _.find(
    fields,
    ({ key, required }) =>
      required &&
      (!formData[key] ||
        // is type collection
        (_.isArray(formData[key]) &&
          _.every(formData[key] as object[], collectionItemIsEmptyPred)) ||
        // is type reference
        (_.isObject(formData[key]) &&
          !_.isArray(formData[key]) &&
          !(formData[key] as { inputValue: string }).inputValue))
  );

  if (!firstInvalidFound) {
    // check required in subInputs of collection
    _.forEach(
      _.filter(fields, (f) => _.includes([collection], f.type)),
      ({ key, subInputs }) => {
        // if found then return
        if (firstInvalidFound) {
          return;
        }

        firstInvalidFound = findSubInputIsInvalid(
          VALID_FUNC_TYPE.requiredFields,
          // filter visible on subInputs
          utils.filterVisible(subInputs.filter((i) => i.required)),
          formData[key]
        );
      }
    );
  }

  if (firstInvalidFound) {
    const { key, name, index } = firstInvalidFound as any;

    // toast message
    toastMessage(t, VALID_FUNC_TYPE.requiredFields, { name });

    firstInvalidKey = buildInvalidKey(key, index);
  }

  return firstInvalidKey;
};

const isValueNotPassPatternTest = (value, pattern) =>
  value && !RegExp(`^${pattern}$`).test(value);

const findSubInputIsInvalid = (invalidType, subInputs, collectionData) => {
  let firstInvalidFound = undefined;

  _.forEach(subInputs, ({ key, name, pattern, type }) => {
    if (firstInvalidFound) {
      return;
    }

    _.forEach(collectionData, (obj, index) => {
      if (firstInvalidFound) {
        return;
      }

      const value = _.includes([reference], type)
        ? obj[key].inputValue
        : obj[key];

      if (
        (invalidType === VALID_FUNC_TYPE.patternFields &&
          isValueNotPassPatternTest(value, pattern)) ||
        (invalidType === VALID_FUNC_TYPE.requiredFields && !value)
      ) {
        firstInvalidFound = { key, name, pattern, index: `${index}` };
      }
    });
  });

  return firstInvalidFound;
};

export const checkIsValidPatternFields = (t, fields, formData) => {
  let firstInvalidKey = undefined;
  let firstInvalidFound = undefined;

  _.forEach(fields, ({ type, key, name, pattern, subInputs }) => {
    // if found then return
    if (firstInvalidFound) {
      return;
    }

    if (
      // case others type
      (!_.includes(
        [date, datetime, daterange, multidates, reference, collection],
        type
      ) &&
        pattern &&
        isValueNotPassPatternTest(formData[key], pattern)) ||
      // case reference
      (_.includes([reference], type) &&
        pattern &&
        isValueNotPassPatternTest(formData[key].inputValue, pattern))
    ) {
      firstInvalidFound = { key, name, pattern };
    }
    // case collection
    else if (_.includes([collection], type)) {
      firstInvalidFound = findSubInputIsInvalid(
        VALID_FUNC_TYPE.patternFields,
        // filter visible on subInputs
        utils.filterVisible(
          subInputs.filter(
            (i) =>
              !_.includes([date, datetime, daterange, multidates], i.type) &&
              i.pattern
          )
        ),
        formData[key]
      );
    }
  });

  if (firstInvalidFound) {
    const { key, name, pattern, index } = firstInvalidFound;
    // toast message
    toastMessage(t, VALID_FUNC_TYPE.patternFields, { name, pattern });

    firstInvalidKey = buildInvalidKey(key, index);
  }

  return firstInvalidKey;
};

// export const checkIsValidCharLimit = (fields, formData, signalInvalidField) =>
//   checkIsValid(
//     formData,
//     (value, key) =>
//       value &&
//       value.length > LIMIT_CHAR &&
//       !_.includes([photo, file, sign], _.find(fields, { key: key }).type),
//     vALID_FUNC_TYPE.charLimit,
//     fields,
//     signalInvalidField
//   );

const toastMessage = (
  t,
  typeValid,
  { name, pattern }: { name: string; pattern?: string }
) => {
  if (typeValid === VALID_FUNC_TYPE.patternFields) {
    FwToast.error(`${t("Field '")}${name}${t("' is not valid")}`);
    console.error({ pattern });
  } else if (typeValid === VALID_FUNC_TYPE.requiredFields) {
    FwToast.error(`${t("Field '")}${name}${t("' is required")}`);
  }
};

const buildInvalidKey = (key, index) => `${key}${index ? `|${index}` : ''}`;
