import _ from 'lodash';

import { Column, FwInputProps, Option } from 'core/model';

import { Unmapped } from './helper';

const UnmappedColumnProps: Unmapped<Column> = ['dropdownID', 'operatorNK'];

const mapColumnToInput = (
  column: Column,
  directInputProps?: FwInputProps
): FwInputProps => {
  const { columnID, key, name, dropdown, ...rest } =
    (_.omit(column, UnmappedColumnProps) as Column) || {};

  return {
    // mapped props
    key: columnID || key || key,
    label: name,
    name: key,
    options: dropdown && _.map(dropdown.options, (o) => new Option(o)),
    // unchanged props
    ...rest,
    ...directInputProps,
  };
};

export { mapColumnToInput };
