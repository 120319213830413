import { Button, IconButton, useBreakpointValue } from '@chakra-ui/react';
import React, { ElementType, FC, ForwardedRef, forwardRef } from 'react';

import {
  FwIcon,
  FwParagraph,
  FwTooltip,
  useFwCssStyle,
  useFwTheme,
} from 'components/base';
import { fwAccent } from 'config/theme/constants';
import { FwButtonProps, fwButtonPT } from 'core/model';

const FwButton: FC<FwButtonProps> = forwardRef(
  (
    {
      // react prop
      children,
      // props with default values
      basic = false,
      disabled = false,
      fluid = false,
      loading = false,
      primary = false,
      responsive = false,
      rounded = false,
      small = false,
      type = 'button',
      // other value props
      as,
      backgroundColor: bgProp,
      borderColor: borderColorProp,
      color: colorProp,
      leftIcon,
      leftIconColor,
      rightIcon,
      rightIconColor,
      tooltip,
      //funcs
      onClick,
    }: FwButtonProps,
    ref: ForwardedRef<HTMLButtonElement>
  ) => {
    const mobile = useBreakpointValue({ base: true, sm: false });
    const { accent } = useFwTheme();
    const { backgroundColor, borderColor, color, _hover } = useFwCssStyle(
      {
        backgroundColor: bgProp,
        borderColor: borderColorProp,
        color: colorProp,
      },
      true
    );

    const iconColor =
      leftIconColor || rightIconColor || primary ? accent : undefined;

    const iconButtonStyle = {
      bg: 'transparent',
      variant: 'outline',
      border: basic ? undefined : 'none',
    };

    const commonProps = {
      isDisabled: disabled,
      isLoading: loading,
    };

    const basicButtonStyle = {
      colorScheme: primary ? fwAccent : undefined,
      bg: basic ? undefined : backgroundColor,
      borderColor,
      color,
      variant: basic ? 'outline' : undefined,
      borderRadius: rounded ? '2em' : undefined,
      size: small ? 'sm' : undefined,
      _hover: _hover?.backgroundColor
        ? { bg: _hover.backgroundColor }
        : undefined,
    };

    return (
      <FwTooltip text={tooltip}>
        {!children && (leftIcon || rightIcon) ? (
          // todo wip#125 improve
          <IconButton
            {...basicButtonStyle}
            {...iconButtonStyle}
            {...commonProps}
            ref={ref}
            as={as as ElementType}
            aria-label={leftIcon}
            icon={<FwIcon color={iconColor} name={leftIcon || rightIcon} />}
            onClick={onClick}
          />
        ) : (
          <Button
            {...basicButtonStyle}
            {...commonProps}
            ref={ref}
            as={as as ElementType}
            type={type}
            width={fluid || (mobile && responsive) ? '100%' : undefined}
            leftIcon={
              <FwIcon
                color={leftIconColor || (primary ? accent : undefined)}
                name={leftIcon}
              />
            }
            rightIcon={
              <FwIcon
                color={rightIconColor || (primary ? accent : undefined)}
                name={rightIcon}
              />
            }
            onClick={onClick}
          >
            <FwParagraph truncated small={small}>
              {children}
            </FwParagraph>
          </Button>
        )}
      </FwTooltip>
    );
  }
);

FwButton.propTypes = fwButtonPT;
FwButton.displayName = 'FwButton';

export default FwButton;
