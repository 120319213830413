import { useDisclosure, useOutsideClick } from '@chakra-ui/react';
import { ChangeEvent, useRef } from 'react';

import { FIELD_TYPE } from 'core/utils/constant';

import {
  useFwInputDateState,
  useFwInputState,
} from '../input/select/useFwControllableState';

const useDateFwPop = (props, params) => {
  return useFwPop(useFwInputDateState, props, params);
};

const useStringFwPop = (props, params) => {
  return useFwPop(useFwInputState, props, params);
};

const useFwPop = (hook, props, params) => {
  const {
    clearable,
    disabled,
    editable,
    name,
    placeholder,
    readOnly,
    saved,
    searchable,
    unchanged,
    onBlur,
    onChange,
  } = props;
  const { rightIcon, onRightIconClick } = params;

  const focusRef = useRef(null);
  const popRef = useRef(null);
  const inputBlurRef = useRef(false);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const openPop = () => {
    inputBlurRef.current = false;
    onOpen();
  };

  const closePop = () => {
    inputBlurRef.current = true;
    onClose();
  };

  const closeAndBlurPop = () => {
    inputBlurRef.current = false;
    onBlur?.(null);
    onClose();
  };

  const notifyAndClose = (
    e: ChangeEvent,
    data: { name: string; value: string; fillData?: object }
  ) => {
    closeAndBlurPop();
    onChange?.(e, data);
  };

  const [
    { dtoValue, displayValue, contentValue },
    { forceContentValue, setContentValue, setDisplayValue, setShouldNotify },
  ] = hook(props, {
    name,
    onChange: notifyAndClose,
  });

  const notify = () => setShouldNotify(true);

  useOutsideClick({
    enabled: isOpen,
    ref: popRef,
    handler: notify,
  });

  const handleClear = () => {
    // todo passing undefined in multiple and range...
    // ...helps knowing shouldNotify must equal true...
    // ...maybe improve this or document it
    forceContentValue(undefined);
  };

  const showClear = dtoValue && !isOpen && clearable && !inputBlurRef.current;

  const popProps = {
    initialFocusRef: editable || searchable ? focusRef : undefined,
    open: isOpen,
    popRef: popRef,
    returnFocusOnClose: false,
  };

  const baseInputProps = {
    autoFocus: false,
    cursor: readOnly ? 'default' : 'pointer',
    disabled: disabled,
    name,
    placeholder,
    readOnly: !(editable || searchable),
    ref: focusRef,
    rightIcon: showClear
      ? 'RiCloseFill'
      : rightIcon || (isOpen ? 'RiArrowUpSLine' : 'RiArrowDownSLine'),
    saved: saved,
    type: FIELD_TYPE.text,
    unchanged: unchanged,
    value: displayValue,
    onBlur: () => {
      if (inputBlurRef.current) {
        notify();
      }
    },
    onClick: readOnly
      ? undefined
      : isOpen && !editable && !searchable
      ? closePop
      : openPop,
    onRightIconClick: readOnly
      ? undefined
      : showClear
      ? handleClear
      : onRightIconClick,
  };

  return {
    dtoValue,
    displayValue,
    contentValue,
    baseInputProps,
    forceContentValue,
    isOpen,
    popProps,
    setContentValue,
    setDisplayValue,
  };
};

export { useDateFwPop, useStringFwPop };
