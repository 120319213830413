import _ from 'lodash';
import { any, bool, func, string } from 'prop-types';
import React, { useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';

import documentApi from 'api/document/documentApi';
import { FwCategoryProps, FwItemProps } from 'core/model';
import { DOWNLOAD_TYPE } from 'core/utils/constant';

import DownloadButton from './DownloadButton';

const { pdf } = DOWNLOAD_TYPE;
const defaultTypesDownload = [pdf];

// map template names and types to dropdown options
const getDownloadOptions = (
  templateNames,
  downloadFileTypes,
  downloadFileName,
  t: TFunction,
  onClick
) =>
  _.map(
    downloadFileTypes
      ? _.map(downloadFileTypes, (fileType) => `${fileType}`.toUpperCase())
      : defaultTypesDownload,
    (fileType) =>
      new FwCategoryProps({
        categoryKey: `${fileType}`,
        name: t(`custom|${fileType}`),
        items: _.map(
          templateNames,
          (name) =>
            new FwItemProps({
              itemKey: `${fileType}|${name}`,
              text: t(`custom|${name}`),
              onClick: () =>
                onClick?.({ templateName: name, fileType, downloadFileName }),
            })
        ),
      })
  );

const DownloadButtonContainer = ({
  disabled,
  name,
  additionalData,
  onClick,
  ...rest
}: any) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [downloadOptions, setDownloadOptions] = useState<FwCategoryProps[]>();

  // get all download templates on dropdown opening
  const handleFetch = async () => {
    if (!downloadOptions) {
      setLoading(true);
      const result = await documentApi.getDownloadTemplates();

      if (result && result.data.downloadTemplateNames) {
        let localDownloadTemps = result.data.downloadTemplateNames;

        if (additionalData && additionalData.downloadTemplates) {
          // has data and has downloadTemplates in data then intersect 2 arrays
          localDownloadTemps = _.intersection(
            localDownloadTemps,
            additionalData.downloadTemplates
          );
        }

        setDownloadOptions(
          getDownloadOptions(
            localDownloadTemps,
            additionalData?.downloadFileTypes,
            additionalData?.downloadFileName,
            t,
            onClick
          )
        );
        setLoading(false);
      }
    }
  };

  // // start download on item click in dropdown
  // const handleChange = (e, { value }) => {
  //   const split = value.split('|', 2);
  //   const fileType = split[0];
  //   const templateName = split[1];

  //   onClick({ templateName, fileType });
  // };

  const buttonProps = {
    ...rest,
    name,
    disabled,
    loading,
    downloadOptions,
    handleFetch,
    // handleChange,
  };

  return <DownloadButton {...buttonProps} />;
};

DownloadButtonContainer.propTypes = {
  additionalData: any,
  disabled: bool,
  name: string,
  onClick: func,
};

export default DownloadButtonContainer;
