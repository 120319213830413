import _ from 'lodash';

import documentApi from 'api/document/documentApi';
import userFilterApi from 'api/user/userFilterApi';
import { FwToast } from 'components/base';
import { BUTTON_TYPE } from 'core/utils/constant';
// import printAll from 'core/utils/print';
import utils from 'core/utils/utils';

import { getExtendDocs } from '../function/document';

const { download, filter, link, popup, print, reset, save, submit } =
  BUTTON_TYPE;

const getEffect = (effectType) => {
  let effect;

  switch (effectType) {
    case download:
      effect = downloadEffect;
      break;
    case filter:
      effect = filterEffect;
      break;
    case link:
      effect = linkEffect;
      break;
    case popup:
      // todo
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      effect = () => async () => {};
      break;
    case print:
      effect = printEffect;
      break;
    case reset:
      effect = resetEffect;
      break;
    case save:
    case submit:
      effect = submitEffect;
      break;
    default:
      break;
  }

  return effect;
};

const downloadEffect =
  ({ templateName, fileType, downloadFileName }) =>
  async (documentRef) => {
    if (templateName && fileType) {
      const res = await documentApi.downloadAs(
        documentRef.current.documentID,
        templateName,
        fileType,
        downloadFileName
      );

      if (res && res.data?.fileUrl) {
        // open to save file
        utils.openInNewTab(res.data.fileUrl);
      }
    }
  };

const filterEffect =
  ({ redirectTo, additionalData, userFilters, setUserFilters }) =>
  async (documentRef, docDataRef) => {
    const dataHasStringValue = {};

    // keep only data which has string value
    _.forOwn(docDataRef.current, (value, key) => {
      if (_.isString(value)) {
        dataHasStringValue[key] = value;
      }
    });

    const editableFilters = utils.mapDataToFilters(dataHasStringValue);

    const res = await userFilterApi.putMany(editableFilters);

    if (res.status === 204) {
      // success then update state
      setUserFilters({
        ...userFilters,
        editableFilters,
      });
    }

    return { location: `${redirectTo || ''}${additionalData?.url || ''}` };
  };

const linkEffect =
  ({ additionalData }) =>
  async () => {
    // delay redirect to wait for end of execution
    setTimeout(() => (window.location.href = additionalData?.url), 1000);

    // prevent state update
    return {};
  };

const printEffect =
  (/*{ template }*/) => async (/*documentRef, docDataRef*/) => {
    // const data = utils.getFullData(documentRef.current, docDataRef.current);
    // printAll([data], template);
  };

const resetEffect = () => async (documentRef, docDataRef, dispatch) => {
  // re-initialize value then dispatch to update state
  _.forOwn(docDataRef.current, (_, key) => {
    docDataRef.current[key] = '';
  });

  dispatch({ docData: _.cloneDeep(docDataRef.current) });
};

const submitEffect =
  ({ t, redirectTo, toStatus }) =>
  async (documentRef, docDataRef, dispatch, linkTemplateID) => {
    let redirect;

    const dataSubmit = _.cloneDeep(docDataRef.current);
    const extendDocs = getExtendDocs(
      dataSubmit,
      documentRef.current,
      linkTemplateID
    );

    // update document
    const formSubmit = {
      ...documentRef.current,
      data: JSON.stringify(dataSubmit),
      status: toStatus,
      extendDocuments: extendDocs,
    };
    formSubmit.template = {
      templateId: formSubmit.template.templateId,
    };

    const response = documentRef.current.active
      ? await documentApi.put(formSubmit)
      : await documentApi.post(null, formSubmit);

    if (response?.status === 204 || response?.status === 201) {
      if (!redirectTo) {
        redirect = { external: true };
        window.location = docDataRef.current['ReturnURL'];
      } else {
        redirect = { location: redirectTo };

        if (response.status === 204) {
          FwToast.success(
            `${t('Document')} ${documentRef.current.number} ${t(
              'was successfully updated'
            )}`
          );
        } else if (response.status === 201) {
          FwToast.success(
            `${t('Document created with ID')} ${(response.data as any).number}`
          );
        }
      }
    } else {
      FwToast.error(t('Action could not be performed'));
    }

    return redirect;
  };

const applyButtonEffect =
  (buttonEffect) =>
  async (documentRef, docDataRef, dispatch, linkTemplateID) => {
    const result = buttonEffect
      ? await buttonEffect(documentRef, docDataRef, dispatch, linkTemplateID)
      : undefined;
    return result;
  };

export {
  getEffect,
  downloadEffect,
  linkEffect,
  printEffect,
  resetEffect,
  submitEffect,
};

export default applyButtonEffect;
