import { Container, HStack, Image, Text, VStack } from '@chakra-ui/react';
import { InferProps } from 'prop-types';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { FwSegment } from 'components/base';
import logoFastercom from 'components/footer/logo-fastercom.png';

const FwFooter: FC<Props> = () => {
  const { t } = useTranslation();

  const text = 'Powered by';

  const textStyle = {
    fontSize: 'xs',
  };

  return (
    <FwSegment as={'footer'} basic frosted>
      <Container as={VStack} spacing="2px">
        <HStack>
          <Text {...textStyle}>{t(`glossary|${text}`)}</Text>
          <a href="https://fasteroptim.com">
            <Image src={logoFastercom} height="20px" />
          </a>
        </HStack>
        <Text {...textStyle}>
          &copy; {new Date().getFullYear()} (v0.6.1-pre11) - Fastercom
        </Text>
      </Container>
    </FwSegment>
  );
};

const propTypes = {};

export type Props = InferProps<typeof propTypes>;

FwFooter.propTypes = propTypes;

export default FwFooter;
